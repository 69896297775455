<template>
   <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
        <a-col style="width:280px">
           <span>子订单号：</span>
           <a-input v-model="orderId" allowClear placeholder='请输入'></a-input>
        </a-col>
         <a-button style="margin:0 12px" icon='search' @click="search">搜索</a-button>
         <a-button v-auth='"withdrawnList:withdrawReward"' type='primary' @click="handleClick">订单退款并撤回订单奖励</a-button>
      </a-row>
      <a-table :columns="columns" :dataSource="tableData" :pagination="false" :rowKey="record => record.buyerId "
             :loading="loading"
             :scroll="{x: true}"
             size="default">
      </a-table>
   </a-card>
</template>

<script>
import api from '@/api'
import moment from 'moment'
export default {
   name:'WithdrawnReward',
   data () {
      return {
         columns:[
            {
               title: '受益人',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励类型',
               dataIndex: 'rewardType',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let map = {
                     '1':'推广积分',
                     '2':'激活积分',
                     '3':'奖励积分',
                     '4':'销售奖',
                     '5':'二级社群奖',
                     '6':'三级社群奖',
                     '7':'二级培育奖',
                     '8':'三级培育奖',
                     '9':'小汇豆销售奖',
                     '10':'小汇豆俱乐部补贴',
                     '11':'小汇豆服务商补贴',
                     '12':'小汇豆俱乐部培育奖',
                     '13':'小汇豆服务商培育奖',
                  }
                  return map[text]
               }
            },
            {
               title: '数值',
               dataIndex: 'rewardAmount',
               align: 'center',
               ellipsis:true
            },
            {
               title: '奖励发放状态',
               dataIndex: 'status',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  let map = {
                     '1':'待发放',
                     '2':'已发放',
                     '3':'已取消',
                     '4':'已冻结',
                     '5':'空号不发',
                     '6':'未激活不发',
                     '9':'扣减成功'
                  }
                  return map[text]
               }
            },
            {
               title: '下发时间',
               dataIndex: 'time',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '-'
               }
            },
         ],
         orderId:undefined,
         tableData:[],
         loading:false
      }
   },
   methods: {
      handleClick () {
         if (!this.orderId) return 
         this.$confirm({
            content:`是否确认订单(${this.orderId})退款并撤回订单奖励？`,
            onOk: () => {
               return new Promise((resolve,reject) => {
                  api.marketManage.withdrawReward({orderId:this.orderId}).then(res => {
                     if (res.code === '00000') {
                        resolve(res.msg)
                     } else {
                        reject(res.msg)
                     }
                  })
               }).then((res) => {
                  this.$message.success(res)
                  this.$router.push({name:'WithdrawnList'})
               }).catch((err) => {
                  this.$message.error(err)
               })
            }
         })
      },
      search () {
         this.loading = true
         api.marketManage.returnList({orderId:this.orderId}).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      }
   }
}
</script>

<style>

</style>